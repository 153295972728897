* {
  font-family: "Macondo", cursive;
  box-sizing: border-box;
}

html, body, .main-layout-container, .main-container, #root {
  height: 100%;
  margin: 0;
}

html {
  background-color: #000;
  color: #FFF;
  background-image: url("../public/images/backgrounds/thai8.jpg");
  background-size: cover;
}

.overlay {
  background-color: rgba(0,0,0,0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100vh;
}

.menu-disclaimer {
  margin-top: 2rem;
}

.slogan {
  padding-left: 5rem;
  padding-right: 5rem;
  background-color: #d42031;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.macondo-regular {
  font-family: "Macondo", cursive;
  font-weight: 400;
  font-style: normal;
}

.logo-container {
  max-width: 300px;
}

.logo-container .logo-link {
  background: transparent;
}

.logo-img {
  width: 100%;
}

.email-container {
  justify-content: center;
  display: flex;

}

.email-link {
  color: #FFF;
  padding: 0 1rem;
  font-size: 1.5rem;
  margin-left: 1rem;
}

.nav-container a {
  color: #FFF;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
}

.social-icons {
  display: flex;
  flex-display: row;
  justify-content: space-evenly;
  font-size: 2rem;
}

.social-icons a {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

a:hover {
  background: #16478e;
}

.footer-container {
  margin-bottom: 2rem;
}

.social-icons a.instagram:hover {
  background: #d42031;
}

.nav-container a:hover {
  background: #16478e;
}

.open-info-days, .open-info-times {
  font-size: 2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.main-layout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.main-container {
  margin: 0 auto;
  width: 800px;
  display: flex;
  flex-direction: column;
}

.home-page-container, .menu-page-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.menu-container {
  height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 1.2rem;
}

.product-category {
  background-color: #d42031;
  width: 50%;
  text-transform: uppercase;
  padding-left: 1rem;
}

.main-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.open-info-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
}

.open-info-times.time {
  font-family: 'Courier New', sans-serif !important;
  font-weight: bold;
  font-size: 1.6rem;
  align-items: center;
  display: flex;
  letter-spacing: -3px;
}

.open-info-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.home-page-content-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.phonelink {
  color: #FFF;
  font-size: 2rem;
}

.products-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.product-sides {
  flex: 1;
}

.product-row {
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.product-inner-row {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.product-row .product-nr, .product-price {
  font-weight: 1000;
  font-size: 1.7rem;
}

.product-row .product-nr {
  width: 55px;
}

.price-and-peppers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pepper-container {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  white-space: nowrap;
}

.product-price {
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 830px) {
  .products-container {
    flex-direction: column;
    gap: 0;
  }

  .main-container {
    width: 100%;
  }

  .product-category {
    width: 100%;
  }

  .menu-container {
    padding-right: 0;
  }

  .product-price {
    width: auto;
  }
}
@media (max-width: 560px) {
  .header-container {
    flex-direction: column;
    margin-top: 4rem;
  }

  .slogan {
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
  }

  .product-row .product-nr, .product-price {
    font-size: 1.3rem;
  }

  .pepper-container {
    font-size: 0.5rem;
  }
}

@media (max-width: 420px) {
  .pepper-container {
    font-size: 0.6rem;
  }
}
